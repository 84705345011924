import {ListItem, ListItemText} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import * as activityEvents from '../constants/activityEvents';
import {formatDate, formatDateWithoutTime, getCallContact, getImageUrl, ImageFilters, phoneToHref} from '../utils';
import {connect} from 'react-redux';
import GridListTile from '@material-ui/core/GridListTile';
import GridList from '@material-ui/core/GridList';
import {openLightbox} from '../actions';

class ActivityEvent extends React.Component {
  getStatus = status => {
    const {dealStatuses, dealOfferStatuses, aggregateType} = this.props;
    if (aggregateType === 'deal')
      return dealStatuses.find(item => item.value === status).label;
    if (aggregateType === 'dealOffer') {
      return dealOfferStatuses.find(item => item.value === status).label;
    }
  };

  getNewDealsPrice = offerPrice => {
    const {priceTypes, currencyCodes} = this.props;
    return offerPrice.value.amount
      + currencyCodes.find(item => offerPrice.value.currency === item.code).label
      + ' ' + priceTypes.find(item => item.value === offerPrice.type).label;
  };

  getDealSource = source => {
    const {dealSources} = this.props;
    return dealSources.find(item => source === item.value).label;
  };

  getNewArea = propertyArea => {
    const {areaUnits} = this.props;
    let unit = areaUnits.find(item => item.value === propertyArea.unit).label;
    return `от ${propertyArea.from} ${unit} до ${propertyArea.to} ${unit}`;
  };

  getOfferArea = offerArea => {
    const {areaUnits} = this.props;
    let unit = areaUnits.find(item => item.value === offerArea.unit).label;
    return `${offerArea.amount} ${unit}`;
  };

  getOfferPrice = offerPrice => {
    const {currencyCodes, priceTypes} = this.props;
    return offerPrice.value.amount
      + ' ' + currencyCodes.find(item => item.code === offerPrice.value.currency).label
      + ' ' + priceTypes.find(item => item.value === offerPrice.type).label;
  };

  getOfferAddressMap = address => {
    if (address.latitude && address.longitude) {
      return `https://www.google.com/maps/place/${address.latitude},${address.longitude}`;
    }
    return '';
  };

  getPropertyPurposesList = purposes => {
    if (!purposes || !purposes.length) {
      return '-';
    }

    return purposes.map(type =>
      this.getPropertyPurposes(type))
      .join(', ');
  };

  makePhotosLabel = eventType => {
    switch (eventType) {
      case activityEvents.OFFER_ADDED_PHOTOS:
        return 'Добавлены фотографии';
      case activityEvents.OFFER_REMOVED_PHOTOS:
        return 'Удалены фотографии';
      case activityEvents.OFFER_ADDED_LAYOUTS:
        return 'Добавлены планировки';
      case activityEvents.OFFER_REMOVED_LAYOUTS:
        return 'Удалены планировки';
      default:
        return '';
    }
  };

  makeEventLabel = eventType => {
    switch (eventType) {
      case activityEvents.OFFER_PROLOGUE_CHANGED:
        return 'Изменен заголовок';
      case activityEvents.OFFER_EPILOGUE_CHANGED:
        return 'Изменена подпись';
      case activityEvents.OFFER_PRICE_HIGH:
        return 'Цена завышена';
      case activityEvents.PROPERTY_DESCRIPTION_CHANGED:
        return 'Изменено описание';
      case activityEvents.OFFER_PRIVATE_CHANGED:
        return 'Закрытый';
      case activityEvents.OFFER_SELL_RENT_RIGHT:
        return 'Переуступка права аренды';
      case activityEvents.OFFER_WITH_DEPOSIT:
        return 'С залогом';
      case activityEvents.OFFER_SUB_RENT:
        return 'Субаренда';
      default:
        return '';
    }
  };

  getEventBoolValue = eventValue => {
    return eventValue === true ? 'Да' : 'Нет';
  };

  getPropertyPurposes = type => {
    const {propertyPurposes} = this.props;
    return propertyPurposes.find(item => item.value === type).label;
  };

  getLabel = (id, entityName) => {
    if (this.props[entityName][id])
      return this.props[entityName][id];
  };

  getAssignees = assignees => {
    return assignees.map(element =>
      this.getLabel(element, 'members'))
      .join(', ');
  };

  getProperties = properties => {
    return properties.map((element, index) => this.getProperty(element, index));
  };

  getProperty = (property, index) => {
    return (
      <React.Fragment key={index}>
        <a href={`/properties/${property}`}>
          {this.getLabel(property, 'properties')}
        </a>
        {'; '}
      </React.Fragment>
    );
  };

  _getOffers = offers => {
    return offers.map(element => this.getOffer(element));
  };

  getOffers = (offers, delimiter) => {
    let newOffer = offers.slice(0, offers.length - 1);
    newOffer = newOffer.map(offer => this.getOffer(offer, delimiter));
    newOffer.push(this.getOffer(offers[offers.length - 1]));
    return newOffer;
  };

  getOffer = (offer, delimiter = '; ') => {
    return (
      <React.Fragment key={offer}>
        <a href={`/offers/${offer}`}>
          {this.getLabel(offer, 'offer')}
        </a>
        {delimiter}
      </React.Fragment>
    );
  };

  getCreatedLabel = () => {
    let dictionary = {
      comment: comment => {
        return <>{'Комментарий: ' + comment}</>;
      },
      assignees: assignees => {
        return <>{'Ответственные: ' + this.getAssignees(assignees)}</>;
      },
      status: status => {
        return <>{'Статус: ' + this.getStatus(status)}</>;
      },
      contact: contact => {
        return (
          <>
            Контакт:{' '}
            <a href={`/contacts/${contact}`}>
              {this.getLabel(contact, 'contacts')}
            </a>
          </>
        );
      },
      deadline: deadline => {
        return <>{'Крайний срок: ' + formatDate(deadline, 'dd MMM yyyy')}</>;
      },
      offerPrice: offerPrice => {
        return <>{'Желаемая цена: ' + this.getNewDealsPrice(offerPrice)}</>;
      },
      free: free => {
        return <>{free ? 'Заявка стала свободной' : 'Заявка взята в работу'}</>;
      },
      offerType: offerType => {
        return <>{'Тип заявки: ' + this.getOfferType(offerType)}</>;
      },
      source: source => {
        return <>{'Источник: ' + this.getDealSource(source)}</>;
      },
      abcClass: abcClass => {
        return <>{'Класс: ' + abcClass}</>;
      },
      propertyType: propertyType => {
        return <>{'Категория недвижимости: ' + this.getPropertyType(propertyType)}</>;
      },
      propertyAdditionalTypes: propertyAdditionalTypes => {
        return <>{'Дополнительные категории: ' + this.getPropertyAdditionalTypes(propertyAdditionalTypes)}</>;
      },
      propertyPurpose: propertyPurpose => {
        return <>{'Назначение объекта: ' + this.getPropertyPurposes(propertyPurpose)}</>;
      },
      offers: offers => {
        return <>Листинги: {this.getOffers(offers)}</>;
      },
      offer: offer => {
        return <>Листинг: {this.getOffer(offer)}</>;
      },
      requirements: requirements => {
        return <>{'Дополнительные требования: ' + requirements}</>;
      },
      commission: commission => {
        return <>{'Комиссия: ' + commission + '%'}</>;
      },
      startAt: startAt => {
        return <>{'Дата заключения: ' + formatDate(startAt, 'dd MMM yyyy')}</>;
      },
      endAt: endAt => {
        return <>{'Дата окончания: ' + formatDate(endAt, 'dd MMM yyyy')}</>;
      },
      type: type => {
        return <>{'Тип договора: ' + this.getContractType(type)}</>;
      },
      no: no => {
        return <>{'Номер договора: ' + no}</>;
      },
      additionalAgreements: additionalAgreements => {
        return <>{'Дополнительные требования: ' + additionalAgreements}</>;
      },
      renewalTerms: renewalTerms => {
        return <>{'Условия пролонгации: ' + renewalTerms}</>;
      },
      title: title => {
        return <>{'Название задачи: ' + title}</>;
      },
      description: description => {
        return <>{'Описание: ' + description}</>;
      },
      properties: properties => {
        return <>
          Объекты: {' '}
          {this.getProperties(properties)}
        </>;
      },
      propertyArea: propertyArea => {
        return <>{'Желаемая  площадь: ' + this.getNewArea(propertyArea)}</>;
      },
      incoming: incoming => {
        return <>Входящее обращение: {incoming ? 'Да' : 'Нет'}</>;
      },
      multiple: multiple => {
        return <>Множественная: {multiple ? 'Да' : 'Нет'}</>;
      },
    };
    const {aggregateType, event} = this.props;
    let result = null;
    let type = null;
    switch (aggregateType) {
      case 'dealOffer':
        type = <>Листинг прикреплен</>;
        break;
      case 'deal':
        type = <>Заявка создана</>;
        break;
      case 'contract':
        type = <>Договор создан</>;
        break;
      case 'task':
        type = <>Задача создана</>;
        break;
      default:
        break;
    }

    result = Object.keys(event.payload).map((property, index) => {
      return dictionary[property] ? (
        <React.Fragment key={index}>
          {dictionary[property](event.payload[property])}
          <>{'\n'}</>
        </React.Fragment>
      ) : null;
    });
    return <>{type}{'\n'}{result}</>;
  };


  getOfferType = offerType => {
    const {offerTypes} = this.props;
    let type = offerTypes.find(item => item.value === offerType);
    return type ? type.label : '';
  };

  getPropertyType = propertyType => {
    const {propertyTypes} = this.props;
    let type = propertyTypes.find(item => item.value === propertyType);
    return type ? type.label : '';
  };

  getPropertyBuildingType = propertyBuildingType => {
    const {buildingTypes} = this.props;
    return buildingTypes[propertyBuildingType] || '';
  };

  getContractType = contractType => {
    const {contractTypes} = this.props;
    let type = contractTypes.find(item => item.value === contractType);
    return type ? type.label : '';
  };

  getPropertyAdditionalTypes = propertyAdditionalTypes => {
    return propertyAdditionalTypes.map(element =>
      this.getPropertyType(element)
    ).join(', ');
  };

  render() {
    const {
      classes,
      event,
      dealClosesReason,
      dealOfferDeclineReasons,
      offerDeclineReasons,
      dealOfferStatuses,
      entityId,
      requestStatuses,
      requestActions,
      requestResults,
      requestFeedback,
      offerTypes,
      offerStatuses,
      offerSources,
      offerArchiveReasons,
      openLightbox,
    } = this.props;

    const {
      propertyId,
      contactId,
      assigneeId,
      observerId,
      coworkerId,
    } = event.payload;
    let Payload = null;
    switch (event.type) {
      //универсальные+старые
      case activityEvents.COMMENT_ADDED:
      case activityEvents.COMMENT_ADDED_TO_CONTRACT:
        Payload = (
          <React.Fragment>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменен комментарий
            </Typography>
            <Typography variant="subtitle1" className={classes.comment}>
              {event.payload.comment}
            </Typography>
          </React.Fragment>
        );
        break;
      case activityEvents.ASSIGNEE_ADDED:
      case activityEvents.ASSIGNEE_ADDED_TO_CONTRACT:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Добавлен ответственный {this.getLabel(assigneeId, 'members')}
          </Typography>
        );
        break;
      case activityEvents.ASSIGNEE_REMOVED:
      case activityEvents.ASSIGNEE_REMOVED_FROM_CONTRACT:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Удален ответственный {this.getLabel(assigneeId, 'members')}
          </Typography>
        );
        break;
      case activityEvents.DOCUMENT_ADDED:
      case activityEvents.DOCUMENT_ADDED_TO_CONTRACT:
      case activityEvents.DOCUMENT_ADDED_TO_TASK:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Добавлен документ {event.payload.documentName}
          </Typography>
        );
        break;
      case activityEvents.DOCUMENT_REMOVED:
      case activityEvents.DOCUMENT_REMOVED_FROM_CONTRACT:
      case activityEvents.DOCUMENT_REMOVED_FROM_TASK:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Удален документ {event.payload.documentName}
          </Typography>
        );
        break;
      case activityEvents.CONTACT_PHONE_CREATED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Добавлен номер телефона{' '}
            <a href={phoneToHref(event.payload.phone)}>
              {event.payload.phone}
            </a>
          </Typography>
        );
        break;
      case activityEvents.CONTACT_PHONE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен номер телефона{' '}
            <a href={phoneToHref(event.payload.phone)}>
              {event.payload.phone}
            </a>
          </Typography>
        );
        break;
      case activityEvents.CONTACT_CHANGED:
      case activityEvents.CONTACT_CHANGED_IN_CONTRACT:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен контакт{' '}
            <a href={`/contacts/${contactId}`}>
              {this.getLabel(contactId, 'contacts')}
            </a>
          </Typography>
        );
        break;
      case activityEvents.CREATED:
      case activityEvents.CONTRACT_CREATED:
      case activityEvents.TASK_CREATED:
      case activityEvents.DEAL_CREATED:
      case activityEvents.DEAL_OFFER_CREATED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            {this.getCreatedLabel()}
          </Typography>
        );
        break;
      //Договор
      case activityEvents.DEADLINE_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен крайний срок принятия решения на {formatDate(event.payload.deadline, 'dd MMM yyyy')}
          </Typography>
        );
        break;
      // Листинги
      case activityEvents.OFFER_STATUS_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен статус: {offerStatuses.find(item => item.value === event.payload.status).label}
          </Typography>
        );
        break;
      case activityEvents.OFFER_SOURCE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен источник: {offerSources.find(item => item.value === event.payload.source).label}
          </Typography>
        );
        break;
      case activityEvents.OFFER_TYPE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен вид сделки: {offerTypes.find(item => item.value === event.payload.type).label}
          </Typography>
        );
        break;
      case activityEvents.OFFER_NAME_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменено название: {event.payload.name}
          </Typography>
        );
        break;
      case activityEvents.OFFER_PRICE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена цена: {this.getOfferPrice(event.payload)}
          </Typography>
        );
        break;
      case activityEvents.OFFER_REVENUE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена комиссия: {event.payload.revenue}%
          </Typography>
        );
        break;
      case activityEvents.OFFER_AREA_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена площадь: {this.getOfferArea(event.payload)}
          </Typography>
        );
        break;
      case activityEvents.OFFER_ADDED_PHOTOS:
      case activityEvents.OFFER_REMOVED_PHOTOS:
      case activityEvents.OFFER_ADDED_LAYOUTS:
      case activityEvents.OFFER_REMOVED_LAYOUTS:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              {this.makePhotosLabel(event.type)}
            </Typography>
            {
              event.payload.images && event.payload.images.length ? (
                <div className={classes.photos}>
                  <GridList cellHeight={50} cols={5} spacing={4}>
                    {event.payload.images.map((photo, i) => (
                      <GridListTile key={i} onClick={() => openLightbox(event.payload.images, i)}>
                        <img
                          src={getImageUrl({
                            filter: ImageFilters.crop,
                            width: 100,
                            height: 100,
                            path: photo,
                          })}
                          alt="фото"
                        />
                      </GridListTile>
                    ))}
                  </GridList>
                </div>
              ) : null
            }
          </>
        );
        break;
      case activityEvents.OFFER_ADDRESS_CHANGED: {
        const mapLink = this.getOfferAddressMap(event.payload);
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен адрес: {event.payload.address}
            {
              mapLink ? (
                <>
                  {'\n'}Метка на карте - <a href={mapLink} target="_blank" rel="noopener noreferrer">Открыть</a>
                </>
              ) : null
            }
          </Typography>
        );
        break;
      }
      case activityEvents.OFFER_PROLOGUE_CHANGED:
      case activityEvents.OFFER_EPILOGUE_CHANGED:
      case activityEvents.PROPERTY_DESCRIPTION_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            {this.makeEventLabel(event.type)}:{'\n'}{event.payload.value}
          </Typography>
        );
        break;
      case activityEvents.OFFER_PURPOSES_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Назначение листинга: {this.getPropertyPurposesList(event.payload.purposes)}
          </Typography>
        );
        break;
      case activityEvents.OFFER_WATERMARK_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            {event.payload.watermark ? 'Добавлены водяные знаки на изображения' : 'Удалены водяные знаки с изображений'}
          </Typography>
        );
        break;
      case activityEvents.OFFER_PRIVATE_CHANGED:
      case activityEvents.OFFER_PRICE_HIGH:
      case activityEvents.OFFER_SELL_RENT_RIGHT:
      case activityEvents.OFFER_WITH_DEPOSIT:
      case activityEvents.OFFER_SUB_RENT:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            {this.makeEventLabel(event.type)}: {this.getEventBoolValue(event.payload.value)}
          </Typography>
        );
        break;
      case activityEvents.OFFER_SEMANTICS_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            {event.payload.semantics ? 'Добавлена семантика' : 'Удалена семантика'}
          </Typography>
        );
        break;
      case activityEvents.PROPERTY_TYPE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена категория на &quot;{this.getPropertyType(event.payload.type)}&quot;
          </Typography>
        );
        break;
      case activityEvents.PROPERTY_BUILDING_TYPE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен тип здания на &quot;{this.getPropertyBuildingType(event.payload.type)}&quot;
          </Typography>
        );
        break;
      case activityEvents.PROPERTY_ADDITIONAL_TYPES_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменены дополнительные категории: {this.getPropertyAdditionalTypes(event.payload.types)}
          </Typography>
        );
        break;
      case activityEvents.OFFER_EXPORTS_ADDED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Листинг опубликован на {event.payload.exports && event.payload.exports.length === 1 ? 'площадке' : 'площадках'}: {event.payload.exports.join(', ')}
          </Typography>
        );
        break;
      case activityEvents.OFFER_EXPORTS_REMOVED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Листинг снят с {event.payload.exports && event.payload.exports.length === 1 ? 'площадки' : 'площадок'}:
            {' '}{event.payload.exports.join(', ')}
          </Typography>
        );
        break;
      case activityEvents.OFFER_ARCHIVED:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Листинг архивирован
              {'\n'}Причина: {offerArchiveReasons.find(item => item.value === event.payload.reason).label}
              {'\n'}Источник: {offerSources.find(item => item.value === event.payload.source).label}
            </Typography>
          </>
        );
        break;
      case activityEvents.OFFER_ORIGIN_CHANGED:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              {
                event.payload.origin ? (
                  <React.Fragment>
                    В листинг закреплен объект{' '}
                    <a href={`/properties/${event.payload.origin}`}>
                      {this.getLabel(event.payload.origin, 'properties')}
                    </a>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Объект удален из листинга
                  </React.Fragment>
                )
              }
            </Typography>
          </>
        );
        break;
      case activityEvents.OFFER_CREATED:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Листинг создан{'\n'}
            </Typography>
            <Typography variant="subtitle1" className={classes.payload}>
              Название: {event.payload.name}{'\n'}
              Статус: {offerStatuses.find(item => item.value === event.payload.status).label}{'\n'}
              {
                event.payload.source ? (
                  <>
                    Источник: {offerSources.find(item => item.value === event.payload.source).label}{'\n'}
                  </>
                ) : null
              }
              Вид сделки: {this.getOfferType(event.payload.type)}{'\n'}
              Площадь: {this.getOfferArea(event.payload.area)}{'\n'}
              Цена: {this.getOfferPrice(event.payload.price)}{'\n'}
              Комиссия: {event.payload.revenue}%{'\n'}
              Адрес: {event.payload.address}{'\n'}
              Объект:{' '}
              <a href={`/properties/${event.payload.origin}`}>
                {this.getLabel(event.payload.origin, 'properties')}
              </a>{'\n'}
              Категория недвижимости: {this.getPropertyType(event.payload.propertyType)}{'\n'}
              {
                event.payload.additionalTypes && event.payload.additionalTypes.length ? (
                  <>
                    Дополнительные категории:
                    {this.getPropertyAdditionalTypes(event.payload.additionalTypes)}{'\n'}
                  </>
                ) : null
              }
              {
                event.payload.purposes && event.payload.purposes.length ? (
                  <>
                    Назначение: {this.getPropertyPurposesList(event.payload.purposes)}{'\n'}
                  </>
                ) : null
              }
              {event.payload.private ? 'Закрытый: Да\n' : ''}
              {event.payload.priceHigh ? 'Цена завышена: Да\n' : ''}
              {event.payload.sellRentRights ? 'Переуступка права аренды: Да\n' : ''}
              {event.payload.subRent ? 'Субаренда: Да\n' : ''}
              {event.payload.withDeposit ? 'С залогом: Да\n' : ''}
              {event.payload.semantics ? 'Добавлена семантика\n' : ''}
              {
                event.payload.ignoreExports && event.payload.ignoreExports.length ? (
                  <>
                    Исключить с площадок: {event.payload.ignoreExports.join(', ')}{'\n'}
                  </>
                ) : null
              }
            </Typography>
          </>
        );
        break;
      //Заявка
      case activityEvents.DEAL_OFFERS_STATUS_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            У прикрепленного <a href={`/dealOffers/${event.payload.id}`}>листинга</a> изменен статус
            на {dealOfferStatuses.find(item => item.value === event.payload.status).label}
          </Typography>
        );
        break;
      case activityEvents.DEAL_OFFERS_SELECTION_CREATED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Создана <a href={`/deals/${entityId}/selections/${event.payload.id}/`}>подборка</a> листингов: {'\n'}
            {this.getOffers(event.payload.offers, ';\n')}{'\n'}
            Отправлена на почту: {event.payload.sent ? 'Да' : 'Нет'}
          </Typography>
        );
        break;
      case activityEvents.DEAL_OFFERS_SELECTION_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена <a href={`/deals/${entityId}/selections/${event.payload.id}/`}>подборка</a> листингов: {'\n'}
            {this.getOffers(event.payload.offers, ';\n')}{'\n'}
            Отправлена на почту: {event.payload.sent ? 'Да' : 'Нет'}
          </Typography>
        );
        break;
      case activityEvents.DEAL_OFFERS_SELECTION_EMAIL_SENT:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            <a href={`/deals/${entityId}/selections/${event.payload.id}/`}>Подборка</a> отправлена на почту
          </Typography>
        );
        break;
      case activityEvents.MULTIPLE_SET_TO_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Заявка отмечена как {event.payload.multiple ? 'множественная' : 'не множественная'}
          </Typography>
        );
        break;
      case activityEvents.OFFER_PRICE_SET_TO_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена желаемая цена на {this.getNewDealsPrice(event.payload.offerPrice)}
          </Typography>
        );
        break;
      case activityEvents.CLOSE_STATUS_SET_TO_DEAL:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Заявка закрыта.{'\n'}
              {event.payload.closeReason ? 'Причина: ' + dealClosesReason[event.payload.closeReason] + '.\n' : ''}
              Комментарий:
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.closeComment}
            </Typography>
          </>
        );
        break;
      case activityEvents.DEAL_FREE:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            {event.payload.free ? 'Заявка стала свободной' : 'Заявка взята в работу'}
          </Typography>
        );
        break;
      case activityEvents.OFFER_TYPE_SET_TO_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен тип заявки на {this.getOfferType(event.payload.offerType)}
          </Typography>
        );
        break;
      case activityEvents.SOURCE_SET_TO_DEAL:
      case activityEvents.REQUEST_SOURCE_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен источник на {this.getDealSource(event.payload.source)}
          </Typography>
        );
        break;
      case activityEvents.ABC_CLASS_SET_TO_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен класс заявки на {event.payload.abcClass}
          </Typography>
        );
        break;
      case activityEvents.PROPERTY_TYPE_SET_TO_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена категория недвижимости на {this.getPropertyType(event.payload.propertyType)}
          </Typography>
        );
        break;
      case activityEvents.PROPERTY_ADDITIONAL_TYPES_SET_TO_DEAL:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменены дополнительные категории на
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {
                this.getPropertyAdditionalTypes(event.payload.propertyAdditionalTypes)
              }
            </Typography>
          </>
        );
        break;
      case activityEvents.PROPERTY_AREA_SET_TO_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена желаемая площадь {this.getNewArea(event.payload.propertyArea)}
          </Typography>
        );
        break;
      case activityEvents.PROPERTY_PURPOSE_SET_TO_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменено назначение объекта недвижимости на {this.getPropertyPurposes(event.payload.propertyPurpose)}
          </Typography>
        );
        break;
      case activityEvents.OFFER_ADDED_TO_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Добавлен листинг{' '}
            <a href={`/offers/${event.payload.offer}`}>{this.getLabel(event.payload.offer, 'offer')}</a>
          </Typography>
        );
        break;
      case activityEvents.OFFER_REMOVED_FROM_DEAL:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Удален листинг <a href={`/offers/${event.payload.offer}`}>{this.getLabel(event.payload.offer, 'offer')}</a>
          </Typography>
        );
        break;
      case activityEvents.REQUIREMENTS_SET_TO_DEAL:
      case activityEvents.REQUEST_REQUIREMENTS_SET:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменены дополнительные требования
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.requirements}
            </Typography>
          </>
        );
        break;
      case activityEvents.REQUEST_ADDITIONAL_SET:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Добавлены дополнительные критерии
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.additional}
            </Typography>
          </>
        );
        break;
      case activityEvents.REQUEST_STATUS_CHANGED:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменен статус: {requestStatuses.find(item => item.value === event.payload.status).label}
            </Typography>
          </>
        );
        break;
      case activityEvents.REQUEST_CREATED:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Заявка создана{'\n'}
            </Typography>
            <Typography variant="subtitle1" className={classes.payload}>
              Дата: {formatDateWithoutTime(event.payload.date)}{'\n'}
              Номер: {event.payload.no}{'\n'}
              Вид сделки: {this.getOfferType(event.payload.type)}{'\n'}
              {
                event.payload.contactName ? (
                  ('Имя контакта: ' + event.payload.contactName + '\n')
                ) : null
              }
              {
                event.payload.contactPhone ?
                  <>
                    {'Номер телефона: '}
                    <a href={getCallContact(entityId)}>Позвонить</a>
                    {'\n'}
                  </>
                  : null
              }
              {
                event.payload.action ? (
                  ('Действие: ' + requestActions.find(item => item.value === event.payload.action).label + '\n')
                ) : null
              }
              {
                event.payload.result ? (
                  ('Результат: ' + (requestResults.find(item => item.value === event.payload.result).label) + '\n')
                ) : null
              }
              {
                event.payload.feedback ? (
                  requestFeedback.find(item => item.value === event.payload.feedback).label + ' ' + (
                    event.payload.deadline ? (' ' + formatDate(event.payload.deadline)) : null
                  ) + '\n'
                ) : null
              }
              {
                event.payload.activity ? (
                  ('Сфера деятельности: ' + event.payload.activity + '\n')
                ) : null
              }
              {
                event.payload.activityDetails ? (
                  ('Детали деятельности: ' + event.payload.activityDetails + '\n')
                ) : null
              }
              {
                event.payload.areaFrom || event.payload.priceTo ? (
                  ('Площадь: ' + (
                    event.payload.areaFrom ? (' от ' + event.payload.areaFrom + ' м²') : ''
                  ) + (event.payload.areaTo ? (' до ' + event.payload.areaTo + ' м²') : '') + '\n')
                ) : null
              }
              {
                event.payload.priceFrom || event.payload.priceTo ? (
                  ('Бюджет: ' + (
                    event.payload.priceFrom ? (' от ' + event.payload.priceFrom + ' ₽') : ''
                  ) + (event.payload.priceTo ? (' до ' + event.payload.priceTo + ' ₽') : '') + '\n')
                ) : null
              }
              {/*{*/}
              {/*  event.payload.offers && event.payload.offers.length > 0 ? (*/}
              {/*    'Листинги: ' + this._getOffers(event.payload.offers) + '\n'*/}
              {/*  ) : null*/}
              {/*}*/}
              {/*{*/}
              {/*  event.payload.assignees && event.payload.assignees.length > 0 ? (*/}
              {/*    'Ответственные: ' + this.getAssignees(event.payload.assignees) + '\n'*/}
              {/*  ) : null*/}
              {/*}*/}
              {/*{*/}
              {/*  event.payload.contact ? (*/}
              {/*    'Контакт: ' + this.getLabel({contact: event.payload.contact}, 'contacts') + '\n'*/}
              {/*  ) : null*/}
              {/*}*/}
            </Typography>
          </>
        );
        break;
      case activityEvents.REQUEST_TYPE_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен вид сделки: {this.getOfferType(event.payload.type)}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_ACTION_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Новое действие: {requestActions.find(item => item.value === event.payload.action).label}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_RESULT_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Новый результат: {requestResults.find(item => item.value === event.payload.result).label}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_FEEDBACK_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            {requestFeedback.find(item => item.value === event.payload.feedback).label}
            {event.payload.deadline ? (' ' + formatDate(event.payload.deadline)) : null}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_ACTIVITY_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена сфера деятельности: {event.payload.label}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_CONTACT_PHONE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен номер телефона:{'  '}
            <a href={phoneToHref(event.payload.value)}>
              {event.payload.value}
            </a>
          </Typography>
        );
        break;
      case activityEvents.REQUEST_CONTACT_NAME_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменено имя контакта: {event.payload.value}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_ACTIVITY_DETAILS_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменены детали деятельности: {event.payload.label}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_AREA_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена площадь:
            {event.payload.from ? (' от ' + event.payload.from + ' м²') : null}
            {event.payload.to ? (' до ' + event.payload.to + ' м²') : null}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_PRICE_CHANGED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен бюджет:
            {event.payload.from ? (' от ' + event.payload.from + ' ₽') : null}
            {event.payload.to ? (' до ' + event.payload.to + ' ₽') : null}
          </Typography>
        );
        break;
      case activityEvents.REQUEST_COPIED_TO_TEAM:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Заявка скопирована в команду &quot;{this.getLabel(event.payload.team, 'teams')}&quot;
          </Typography>
        );
        break;
      case activityEvents.REQUEST_VIEW_DONE:
        Payload = <Typography variant="subtitle1" className={classes.payload}>Выполнен показ</Typography>;
        break;
      //Договор
      case activityEvents.CONTRACT_ARCHIVED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Договор архивирован
          </Typography>
        );
        break;
      case activityEvents.CONTRACT_ADDED_TO_PROPERTY:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Договор прикреплен к объекту{' '}
            <a href={`/properties/${propertyId}`}>
              {this.getLabel(event.payload.propertyId, 'properties')}
            </a>
          </Typography>
        );
        break;
      case activityEvents.CONTRACT_REMOVED_FROM_PROPERTY:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Договор откреплен от объекта{' '}
            <a href={`/properties/${propertyId}`}>
              {this.getLabel(event.payload.propertyId, 'properties')}
            </a>
          </Typography>
        );
        break;
      case activityEvents.CONTRACT_COMMISSION_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена комиссия на {event.payload.commission}%
          </Typography>
        );
        break;
      case activityEvents.CONTRACT_START_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена дата заключения {formatDate(event.payload.starAt, 'dd MMM yyyy')}
          </Typography>
        );
        break;
      case activityEvents.CONTRACT_END_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена дата окончания {formatDate(event.payload.endAt, 'dd MMM yyyy')}
          </Typography>
        );
        break;
      case activityEvents.CONTRACT_TYPE_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен тип договора на {this.getContractType(event.payload.type)}
          </Typography>
        );
        break;
      case activityEvents.CONTRACT_NUMBER_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен номер договора на {event.payload.no}
          </Typography>
        );
        break;
      case activityEvents.CONTRACT_ADDITIONAL_AGREEMENT:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменены дополнительные требования
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.additionalAgreements}
            </Typography>
          </>
        );
        break;
      case activityEvents.CONTRACT_RENEWAL_TERMS_SET:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменены условия пролонгации
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.renewalTerms}
            </Typography>
          </>
        );
        break;
      //Задачи
      case activityEvents.DEAL_DECLINED_OFFER:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Отказ от <a href={`/offers/${event.payload.offer}`}>листинга</a>.{'\n'}
              Причина: {offerDeclineReasons.find(element => element.value === event.payload.reason).label}.{'\n'}
              Комментарий:
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.comment}
            </Typography>
          </>
        );
        break;
      case activityEvents.COMMENT_ADDED_TO_TASK:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Добавлен комментарий
            </Typography>
            <Typography variant="subtitle1" className={classes.comment}>
              {event.payload.comment}
            </Typography>
          </>
        );
        break;
      case activityEvents.TITLE_SET_TO_TASK:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменено название задачи
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.title}
            </Typography>
          </>
        );
        break;
      case activityEvents.DESCRIPTION_SET_TO_TASK:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменено описание
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.description}
            </Typography>
          </>
        );
        break;
      case activityEvents.COWORKER_ADDED_TO_TASK:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Добавлен соисполнитель {this.getLabel(coworkerId, 'members')}
          </Typography>
        );
        break;
      case activityEvents.COWORKER_REMOVED_FROM_TASK:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Убран соисполнитель {this.getLabel(coworkerId, 'members')}
          </Typography>
        );
        break;
      case activityEvents.OBSERVER_ADDED_TO_TASK:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Добавлен наблюдатель {this.getLabel(observerId, 'members')}
          </Typography>
        );
        break;
      case activityEvents.OBSERVER_REMOVED_FROM_TASK:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Убран наблюдатель {this.getLabel(observerId, 'members')}
          </Typography>
        );
        break;
      case activityEvents.TASK_CLOSED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Задача закрыта
          </Typography>
        );
        break;
      case activityEvents.TASK_REASSIGNED:
      case activityEvents.ASSIGNEE_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен ответственный {event.payload.id ? 'на ' + this.getLabel(event.payload.id, 'members') : null}
          </Typography>
        );
        break;
      case activityEvents.TASK_REOPENED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Задача открыта
          </Typography>
        );
        break;
      case activityEvents.TASK_RESCHEDULED: {
        const oldDeadline = formatDate(event.payload.oldDeadline, 'EEEEEE, dd MMM yyyy, HH:mm');
        const newDeadline = formatDate(event.payload.newDeadline, 'EEEEEE, dd MMM yyyy, HH:mm');
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Задача перенесена: {oldDeadline} &rarr; {newDeadline}
          </Typography>
        );
        break;
      }
      case activityEvents.STATUS_SET:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменен статус на {this.getStatus(event.payload.status)}
          </Typography>
        );
        break;
      case activityEvents.POTENTIAL_END_AT_SET_TO_DEAL_OFFER:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Изменена потенциальная дата оплаты: {formatDate(event.payload.potentialEndAt, 'dd MMM yyyy')}
          </Typography>
        );
        break;
      //Сделки
      case activityEvents.DEAL_OFFER_CLOSED:
        Payload = (
          <Typography variant="subtitle1" className={classes.payload}>
            Сделка закрыта.{'\n'}
            Фактическая дата оплаты: {formatDate(event.payload.endAt, 'dd MMM yyyy')}.{'\n'}
            Фактическая комиссия: {event.payload.commission} ₽.{'\n'}
            Сданная площадь: {event.payload.area} м².{'\n'}
          </Typography>
        );
        break;
      case activityEvents.DEAL_OFFER_DECLINED:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Отказ от объекта.{'\n'}
              Причина: {dealOfferDeclineReasons.find(element => element.value === event.payload.reason).label}.{'\n'}
              Комментарий:
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.comment}
            </Typography>
          </>
        );
        break;
      case activityEvents.ADDITIONAL_SET_TO_DEAL_OFFER:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Изменена дополнительная информация
            </Typography>
            <Typography variant="subtitle1" className={classes.additionalText}>
              {event.payload.additional}
            </Typography>
          </>
        );
        break;
      case activityEvents.REMINDER_CREATED:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Добавлено напоминание на {formatDate(event.payload.datetime, 'EEEEEE, dd MMM yyyy, HH:mm')}
            </Typography>
          </>
        );
        break;
      case activityEvents.REMINDER_REMOVED:
        Payload = (
          <>
            <Typography variant="subtitle1" className={classes.payload}>
              Удалено напоминание на {formatDate(event.payload.datetime, 'EEEEEE, dd MMM yyyy, HH:mm')}
            </Typography>
          </>
        );
        break;
      default:
    }

    return (
      <ListItem dense className={classes.activityItem}>
        <ListItemText
          disableTypography={true}
          primary={Payload}
          className={classes.activityText}
          secondary={
            <Typography color="textSecondary" className={classes.metadata}>
              <span className={classes.createdAt}>{formatDate(event.createdAt, 'EEEEEE, dd MMM yyyy, HH:mm')}</span>
              <span>{event.member}</span>
            </Typography>
          }
        />
      </ListItem>
    );
  }
}

const styles = () => ({
  metadata: {
    fontSize: 'inherit',
    display: 'flex',
    flexWrap: 'wrap-reverse',
  },
  createdAt: {
    flex: 1,
  },
  authorLink: {
    marginLeft: 6,
  },
  payload: {
    fontSize: 'inherit',
  },
  comment: {
    fontSize: 'inherit',
    wordWrap: 'break-word',
    padding: '2px 6px',
    marginBottom: 4,
    backgroundColor: colors.amber[50],
    border: `1px solid ${colors.amber[100]}`,
    borderRadius: 2,
  },
  additionalText: {
    fontSize: 'inherit',
    wordWrap: 'break-word',
    padding: '2px 6px',
    marginBottom: 4,
    backgroundColor: colors.indigo[50],
    border: `1px solid ${colors.indigo[100]}`,
    borderRadius: 2,
  },
  activityText: {
    whiteSpace: 'pre-line',
    paddingRight: 0,
  },
  activityItem: {
    backgroundColor: '#fff',
    borderRadius: 3,
    marginBottom: 4,
  },
  photos: {
    marginTop: 5,
    marginBottom: 5,
  },
});

export default connect(
  state => ({
    state,
    properties: state.root.entityNames.propertyNames,
    offer: state.root.entityNames.offerNames,
    contacts: state.root.entityNames.contactNames,
    members: state.root.entityNames.memberNames,
    teams: state.root.entityNames.teamNames,
    contractTypes: state.root.classification.contractTypes,
    currencyCodes: state.root.classification.currencyCodes,
    offerTypes: state.root.classification.offerTypes,
    propertyTypes: state.root.classification.propertyTypes,
    priceTypes: state.root.classification.priceTypes,
    areaUnits: state.root.classification.areaUnits,
    dealStatuses: state.root.classification.dealStatuses,
    propertyPurposes: state.root.classification.propertyPurposes,
    dealSources: state.root.classification.dealSources,
    dealClosesReason: state.root.classification.dealClosesReason,
    dealOfferDeclineReasons: state.root.classification.dealOfferDeclineReasons,
    offerDeclineReasons: state.root.classification.offerDeclineReasons,
    dealOfferStatuses: state.root.classification.dealOfferStatuses,
    requestStatuses: state.root.classification.requestStatuses,
    requestActions: state.root.classification.requestActions,
    requestResults: state.root.classification.requestResults,
    requestFeedback: state.root.classification.requestFeedback,
    offerStatuses: state.root.classification.offerStatuses,
    offerSources: state.root.classification.offerSources,
    offerArchiveReasons: state.root.classification.offerArchiveReasons,
    buildingTypes: state.root.classification.buildingTypes,
  }),
  {
    openLightbox,
  }
)(withStyles(styles)(ActivityEvent));
